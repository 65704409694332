import { useMemo } from 'react';

export default (t) => useMemo(() => ({
  speedLimit: {
    name: t('attributeSpeedLimit'),
    type: 'number',
    subtype: 'speed',
    },
    tankCapacity: {
        name: t('attributeTankCapacity'),
        type: 'number',
        subtype: 'volume',
    },
}), [t]);
