import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
    Table, TableRow, TableCell, TableHead, TableBody,
} from '@mui/material';
//import LoginIcon from '@mui/icons-material/Login';
import makeStyles from '@mui/styles/makeStyles';
import { useEffectAsync } from '../reactHelper';
import { formatBoolean } from '../common/util/formatter';
import { useTranslation } from '../common/components/LocalizationProvider';
import PageLayout from '../common/components/PageLayout';
import SettingsMenu from './components/SettingsMenu';
import CollectionFab from './components/CollectionFab';
import CollectionActions from './components/CollectionActions';
import TableShimmer from '../common/components/TableShimmer';
//import { useManager } from '../common/util/permissions';
import SearchHeader, { filterByKeyword } from './components/SearchHeader';
import { usersActions } from '../store';

const useStyles = makeStyles((theme) => ({
    columnAction: {
        width: '1%',
        paddingRight: theme.spacing(1),
    },
}));

const UsersPage = () => {
    const classes = useStyles();
    const t = useTranslation();
    const dispatch = useDispatch();
   // const manager = useManager();
    const items = useSelector((state) => state.users.items);
    const loaded = useSelector((state) => state.users.loaded);
    const [searchKeyword, setSearchKeyword] = useState('');

  /*  const handleLogin = useCatch(async (userId) => {
        const response = await fetch(`/api/session/${userId}`);
        if (response.ok) {
            window.location.replace('/');
        } else {
            throw Error(await response.text());
        }
    });*/

   /* const loginAction = {
        title: t('loginLogin'),
        icon: (<LoginIcon fontSize="small" />),
        handler: handleLogin,
    };*/

    useEffectAsync(async () => {
        if (!loaded) {
            try {
                const response = await fetch('/api/tracking/users');
                if (response.ok) {
                    const result = await response.json();
                    dispatch(usersActions.update(result));
                } else {
                    throw Error(await response.text());
                }
            } catch {
                throw Error('Error');
            }
        }
    }, []);

    return (
        <PageLayout menu={<SettingsMenu />} breadcrumbs={['settingsTitle', 'settingsUsers']}>
            <SearchHeader keyword={searchKeyword} setKeyword={setSearchKeyword} />
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>{t('sharedName')}</TableCell>
                        <TableCell>{t('userUserName')}</TableCell>
                        <TableCell>{t('sharedDisabled')}</TableCell>
                        <TableCell className={classes.columnAction} />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {loaded ? Object.values(items).filter(filterByKeyword(searchKeyword)).map((item) => (
                        <TableRow key={item.id}>
                            <TableCell>{item.name}</TableCell>
                            <TableCell>{item.login}</TableCell>
                            <TableCell>{formatBoolean(item.disabled, t)}</TableCell>
                            <TableCell className={classes.columnAction} padding="none">
                                <CollectionActions
                                    itemId={item.id}
                                    editPath="/settings/user"
                                    endpoint="users"
                                    removeAction={usersActions.remove}
                                   /*customAction={manager ? loginAction : null}*/
                                />
                            </TableCell>
                        </TableRow>
                    )) : (<TableShimmer columns={5} endAction />)}
                </TableBody>
            </Table>
            <CollectionFab editPath="/settings/user" />
        </PageLayout>
    );
};

export default UsersPage;
