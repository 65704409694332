import { createSlice } from '@reduxjs/toolkit';

const { reducer, actions } = createSlice({
    name: 'attributes',
    initialState: {
        items: {},
        loaded: false,
    },
    reducers: {
        update(state, action) {
            action.payload.forEach((item) => state.items[item.id] = item);
            if (!state.loaded) {
                state.loaded = true;
            }
        },
        remove(state, action) {
            delete state.items[action.payload];
        },
    },
});

export { actions as attributesActions };
export { reducer as attributesReducer };
