import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
    Table, TableRow, TableCell, TableHead, TableBody,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from '../common/components/LocalizationProvider';
import PageLayout from '../common/components/PageLayout';
import SettingsMenu from './components/SettingsMenu';
import CollectionFab from './components/CollectionFab';
import CollectionActions from './components/CollectionActions';
import SearchHeader, { filterByKeyword } from './components/SearchHeader';
import { groupsActions } from '../store';

const useStyles = makeStyles((theme) => ({
    columnAction: {
        width: '1%',
        paddingRight: theme.spacing(1),
    },
}));

const GroupsPage = () => {
    const classes = useStyles();
    const t = useTranslation();
    const items = useSelector((state) => state.groups.items);
    const [searchKeyword, setSearchKeyword] = useState('');

    return (
        <PageLayout menu={<SettingsMenu />} breadcrumbs={['settingsTitle', 'settingsGroups']}>
            <SearchHeader keyword={searchKeyword} setKeyword={setSearchKeyword} />
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>{t('sharedName')}</TableCell>
                        <TableCell className={classes.columnAction} />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Object.values(items).filter(filterByKeyword(searchKeyword)).map((item) => (
                        <TableRow key={item.id}>
                            <TableCell>{item.name}</TableCell>
                            <TableCell className={classes.columnAction} padding="none">
                                <CollectionActions
                                    itemId={item.id}
                                    editPath="/settings/group"
                                    endpoint="groups"
                                    removeAction={groupsActions.remove}
                                />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <CollectionFab editPath="/settings/group" />
        </PageLayout>
    );
};

export default GroupsPage;
