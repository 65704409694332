import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    TextField,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditItemView from './components/EditItemView';
import EditAttributesAccordion from './components/EditAttributesAccordion';
import SelectField from '../common/components/SelectField';
//import deviceCategories from '../common/util/deviceCategories';
import LinkField from '../common/components/LinkField';
import { useTranslation } from '../common/components/LocalizationProvider';
//import useDeviceAttributes from '../common/attributes/useDeviceAttributes';
//import { useManager } from '../common/util/permissions';
import SettingsMenu from './components/SettingsMenu';
import useCommonDeviceAttributes from '../common/attributes/useCommonDeviceAttributes';
import useFeatures from '../common/util/useFeatures';
import { formatNotificationTitle } from '../common/util/formatter';
import { prefixString } from '../common/util/stringUtils';

const useStyles = makeStyles((theme) => ({
    details: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        paddingBottom: theme.spacing(3),
    },
}));

const DevicePage = () => {
    const classes = useStyles();
    const t = useTranslation();
    const { id } = useParams();
    //const manager = useManager();

    const commonDeviceAttributes = useCommonDeviceAttributes(t);
    // const deviceAttributes = useDeviceAttributes(t);

    const features = useFeatures();
    const device = useSelector((state) => state.devices.items[id]);
    const groups = useSelector((state) => state.groups.items);

    const [item, setItem] = useState({ ...device });

    const validate = () => item && item.name;

    return (
        <EditItemView
            endpoint="devices"
            item={item}
            setItem={setItem}
            validate={validate}
            menu={<SettingsMenu />}
            breadcrumbs={['sharedDevice']}
        >
            {item && (
                <>
                    <Accordion defaultExpanded>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="subtitle1">
                                {t('sharedRequired')}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.details}>
                            <TextField
                                value={item.name || ''}
                                onChange={(event) => setItem({ ...item, name: event.target.value })}
                                label={t('sharedName')}
                            />
                            <SelectField
                                value={item.groupId || 0}
                                onChange={(event) => setItem({ ...item, groupId: Number(event.target.value) })}
                                data={Object.values(groups)}
                                label={t('groupParent')}
                            />
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="subtitle1">
                                {t('sharedExtra')}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.details}>
                            <TextField
                                value={item.sim || ''}
                                onChange={(event) => setItem({ ...item, sim: event.target.value })}
                                label={t('sharedPhone')}
                                disabled
                            />
                            <TextField
                                value={item.model || ''}
                                onChange={(event) => setItem({ ...item, model: event.target.value })}
                                label={t('deviceModel')}
                                disabled
                            />
                            <TextField
                                value={t(`category${item.category.replace(/^\w/, (c) => c.toUpperCase())}`) || item.category || ''}
                                emptyValue={null}
                                onChange={(event) => setItem({ ...item, category: event.target.value })}
                                /*data={deviceCategories.map((category) => ({
                                    id: category,
                                    name: t(`category${category.replace(/^\w/, (c) => c.toUpperCase())}`),
                                }))} */
                                label={t('deviceCategory')}
                                disabled
                            />
                            <TextField
                                label={t('userExpirationTime')}
                                type="date"
                                value={(item.anniversary && moment(item.anniversary).locale('en').format(moment.HTML5_FMT.DATE)) || '2099-01-01'}
                                onChange={(e) => setItem({ ...item, anniversary: moment(e.target.value, moment.HTML5_FMT.DATE).format() })}
                                disabled
                            />
                        </AccordionDetails>
                    </Accordion>
                    <EditAttributesAccordion
                        attributes={item.attributes}
                        setAttributes={(attributes) => setItem({ ...item, attributes })}
                        definitions={{ ...commonDeviceAttributes }}
                    />
                    {item.id && (
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="subtitle1">
                                    {t('sharedConnections')}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails className={classes.details}>
                                <LinkField
                                    endpointAll="/api/tracking/commands"
                                    endpointLinked={`/api/tracking/commands?deviceId=${item.id}`}
                                    baseId={item.id}
                                    keyBase="deviceId"
                                    keyLink="commandId"
                                    titleGetter={(it) => t(prefixString('command', it.type))}
                                    label={t('sharedSavedCommands')}
                                />
                                <LinkField
                                    endpointAll="/api/tracking/notifications"
                                    endpointLinked={`/api/tracking/notifications?deviceId=${item.id}`}
                                    baseId={item.id}
                                    keyBase="deviceId"
                                    keyLink="notificationId"
                                    titleGetter={(it) => formatNotificationTitle(t, it)}
                                    label={t('sharedNotifications')}
                                />
                                {/*<LinkField
                                    endpointAll="/api/geofences"
                                    endpointLinked={`/api/geofences?deviceId=${item.id}`}
                                    baseId={item.id}
                                    keyBase="deviceId"
                                    keyLink="geofenceId"
                                    label={t('sharedGeofences')}
                                />*/}
                                <LinkField
                                    endpointAll="/api/tracking/drivers"
                                    endpointLinked={`/api/tracking/drivers?deviceId=${item.id}`}
                                    baseId={item.id}
                                    keyBase="deviceId"
                                    keyLink="driverId"
                                    label={t('sharedDrivers')}
                                />
                                {!features.disableComputedAttributes && (
                                    <LinkField
                                        endpointAll="/api/tracking/attributes/computed"
                                        endpointLinked={`/api/tracking/attributes/computed?deviceId=${item.id}`}
                                        baseId={item.id}
                                        keyBase="deviceId"
                                        keyLink="attributeId"
                                        titleGetter={(it) => it.name}
                                        label={t('sharedComputedAttributes')}
                                    />
                                )}

                                {/* <LinkField
                                        endpointAll="/api/maintenance"
                                        endpointLinked={`/api/maintenance?deviceId=${item.id}`}
                                        baseId={item.id}
                                        keyBase="deviceId"
                                        keyLink="maintenanceId"
                                        label={t('sharedMaintenance')}
                                    />*/}
                            </AccordionDetails>
                        </Accordion>
                    )}
                </>
            )}
        </EditItemView>
    );
};

export default DevicePage;
