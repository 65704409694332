import { loadImage, prepareIcon } from './mapUtil';

import directionSvg from '../../resources/images/direction.svg';
import backgroundSvg from '../../resources/images/background.svg';
import carSvg from '../../resources/images/icon/car.svg';
import selected from '../../resources/images/selected.png';
import car000000 from '../../resources/images/icon/_000000.png';
import car000080 from '../../resources/images/icon/_000080.png';
import car006400 from '../../resources/images/icon/_006400.png';
import car00bfff from '../../resources/images/icon/_00bfff.png';
import car00ffff from '../../resources/images/icon/_00ffff.png';
import car20b2aa from '../../resources/images/icon/_20b2aa.png';
import car3cb371 from '../../resources/images/icon/_3cb371.png';
import car4169e1 from '../../resources/images/icon/_4169e1.png';
import car5f9ea0 from '../../resources/images/icon/_5f9ea0.png';
import car778899 from '../../resources/images/icon/_778899.png';
import car7b68ee from '../../resources/images/icon/_7b68ee.png';
import car7cfc00 from '../../resources/images/icon/_7cfc00.png';
import car7fffd4 from '../../resources/images/icon/_7fffd4.png';
import car800080 from '../../resources/images/icon/_800080.png';
import car808000 from '../../resources/images/icon/_808000.png';
import car90ee90 from '../../resources/images/icon/_90ee90.png';
import car9400d3 from '../../resources/images/icon/_9400d3.png';
import cara52a2a from '../../resources/images/icon/_a52a2a.png';
import carb0e0e6 from '../../resources/images/icon/_b0e0e6.png';
import carb22222 from '../../resources/images/icon/_b22222.png';
import carbdb76b from '../../resources/images/icon/_bdb76b.png';
import carc0c0c0 from '../../resources/images/icon/_c0c0c0.png';
import card2691e from '../../resources/images/icon/_d2691e.png';
import cardb7093 from '../../resources/images/icon/_db7093.png';
import cardeb887 from '../../resources/images/icon/_deb887.png';
import care9967a from '../../resources/images/icon/_e9967a.png';
import caree82ee from '../../resources/images/icon/_ee82ee.png';
import carf0f8ff from '../../resources/images/icon/_f0f8ff.png';
import carf0fff0 from '../../resources/images/icon/_f0fff0.png';
import carf4a460 from '../../resources/images/icon/_f4a460.png';
import carf5f5dc from '../../resources/images/icon/_f5f5dc.png';
import carff00ff from '../../resources/images/icon/_ff00ff.png';
import carff6347 from '../../resources/images/icon/_ff6347.png';
import carffb6c1 from '../../resources/images/icon/_ffb6c1.png';
import carffd700 from '../../resources/images/icon/_ffd700.png';
import carffdab9 from '../../resources/images/icon/_ffdab9.png';
import carffe4e1 from '../../resources/images/icon/_ffe4e1.png';
import carffebcd from '../../resources/images/icon/_ffebcd.png';
import carfffacd from '../../resources/images/icon/_fffacd.png';
import carfffff0 from '../../resources/images/icon/_fffff0.png';
import violet from '../../resources/images/icon/violet.png';
import yellow from '../../resources/images/icon/yellow.png';
import blue from '../../resources/images/icon/blue.png';
import red from '../../resources/images/icon/red.png';

export const mapIcons = {
    car: carSvg,
    car000000,
    car000080,
    car006400,
    car00bfff,
    car00ffff,
    car20b2aa,
    car3cb371,
    car4169e1,
    car5f9ea0,
    car778899,
    car7b68ee,
    car7cfc00,
    car7fffd4,
    car800080,
    car808000,
    car90ee90,
    car9400d3,
    cara52a2a,
    carb0e0e6,
    carb22222,
    carbdb76b,
    carc0c0c0,
    card2691e,
    cardb7093,
    cardeb887,
    care9967a,
    caree82ee,
    carf0f8ff,
    carf0fff0,
    carf4a460,
    carf5f5dc,
    carff00ff,
    carff6347,
    carffb6c1,
    carffd700,
    carffdab9,
    carffe4e1,
    carffebcd,
    carfffacd,
    carfffff0,
    violet,
    yellow,
    blue,
    red,
};

export const mapIconKey = (category) => (mapIcons.hasOwnProperty(category) ? category : 'red');

export const mapImages = {};

export default async () => {
  const background = await loadImage(backgroundSvg);
  mapImages.background = await prepareIcon(background);
    mapImages.direction = await prepareIcon(await loadImage(directionSvg));
    mapImages.selected = await prepareIcon(await loadImage(selected));
  await Promise.all(Object.keys(mapIcons).map(async (category) => {
    const results = [];
      results.push(loadImage(mapIcons[category]).then((icon) => {
          mapImages[category] = prepareIcon(icon);
      }));
    await Promise.all(results);
  }));
};
