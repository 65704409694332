//import { useSelector } from 'react-redux';
import { useTranslation } from '../../common/components/LocalizationProvider';
import { useAttributePreference } from '../../common/util/preferences';

const styleCustom = ({ tiles, minZoom, maxZoom, attribution }) => {
  const source = {
    type: 'raster',
    tiles,
    attribution,
    tileSize: 256,
    minzoom: minZoom,
    maxzoom: maxZoom,
  };
  Object.keys(source).forEach((key) => source[key] === undefined && delete source[key]);
  return {
    version: 8,
    sources: {
      custom: source,
    },
    //glyphs: 'https://cdn.traccar.com/map/fonts/{fontstack}/{range}.pbf',
      glyphs: './font/{fontstack}/{range}.pbf',
    layers: [{
      id: 'custom',
      type: 'raster',
      source: 'custom',
    }],
  };
};

export default () => {
  const t = useTranslation();
    const customMapUrl = useAttributePreference('mapUrl');

  return [
      {
          id: 'googleMap',
          title: 'G Map',
          style: styleCustom({
              tiles: ['https://mt0.google.com/vt/lyrs=m&hl=fr&x={x}&y={y}&z={z}'],
              maxZoom: 19,
          }),
          available: true,
      },
      {
          id: 'googleSat',
          title: 'G Sat',
          style: styleCustom({
              tiles: ['https://mt0.google.com/vt/lyrs=y&hl=fr&x={x}&y={y}&z={z}'],
              maxZoom: 19,
          }),
          available: true,
      },
      {
          id: 'arcGisMap',
          title: 'ArcGis Map',
          style: styleCustom({
              tiles: ['https://services.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}'],
              maxZoom: 19,
          }),
          available: true,
      },
      {
          id: 'arcGisSat',
          title: 'ArcGis Sat',
          style: styleCustom({
              tiles: ['https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'],
              maxZoom: 19,
          }),
          available: true,
      },
    {
      id: 'osm',
      title: t('mapOsm'),
      style: styleCustom({
        tiles: ['https://tile.openstreetmap.org/{z}/{x}/{y}.png'],
        maxZoom: 19,
        attribution: '© <a target="_top" rel="noopener" href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      }),
      available: true,
    },
    {
      id: 'custom',
      title: t('mapCustomLabel'),
      style: styleCustom({
        tiles: [customMapUrl],
      }),
      available: !!customMapUrl,
    },
  ];
};
