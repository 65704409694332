import React from 'react';
import { useSelector } from 'react-redux';

import {
    Typography, Container, Paper, AppBar, Toolbar, IconButton, Table, TableHead, TableRow, TableCell, TableBody,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams } from 'react-router-dom';
import { prefixString } from '../common/util/stringUtils';
import { useTranslation } from '../common/components/LocalizationProvider';
import PositionValue from '../common/components/PositionValue';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        overflow: 'auto',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
}));

const PositionPage = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const t = useTranslation();

    const { id } = useParams();

    const item = useSelector((state) => state.positions.items[id]);
    const deviceName = useSelector((state) => {
        if (item) {
            const device = state.devices.items[item.deviceId];
            if (device) {
                return device.name;
            }
        }
        return null;
    });

    return (
        <div className={classes.root}>
            <AppBar position="sticky" color="inherit">
                <Toolbar>
                    <IconButton color="inherit" edge="start" sx={{ mr: 2 }} onClick={() => navigate(-1)}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h6">
                        {deviceName}
                    </Typography>
                </Toolbar>
            </AppBar>
            <div className={classes.content}>
                <Container maxWidth="sm">
                    <Paper>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t('stateName')}</TableCell>
                                    <TableCell>{t('sharedName')}</TableCell>
                                    <TableCell>{t('stateValue')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {item && Object.getOwnPropertyNames(item).filter((it) => it !== 'attributes').map((property) => (
                                    <TableRow key={property}>
                                        <TableCell>{property}</TableCell>
                                        <TableCell><strong>{t(prefixString('position', property))}</strong></TableCell>
                                        <TableCell><PositionValue position={item} property={property} /></TableCell>
                                    </TableRow>
                                ))}
                                {item && Object.getOwnPropertyNames(item.attributes)
                                    .filter((it) => it !== 'cellTower')
                                    .map((attribute) => (
                                        <TableRow key={attribute}>
                                            <TableCell>{attribute}</TableCell>
                                            <TableCell><strong>{t(prefixString('position', attribute)) || t(prefixString('device', attribute))}</strong></TableCell>
                                            <TableCell><PositionValue position={item} attribute={attribute} /></TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </Paper>
                </Container>
            </div>
        </div>
    );
};

export default PositionPage;
