import { createSlice } from '@reduxjs/toolkit';

const { reducer, actions } = createSlice({
    name: 'commands',
    initialState: {
        items: {},
        loaded: false,
        types: [],
        typesLoaded: false,
    },
    reducers: {
        update(state, action) {
            action.payload.forEach((item) => state.items[item.id] = item);
            state.loaded = true;
        },
        updateTypes(state, action) {
            state.types = action.payload;
            state.typesLoaded = true;
        },
        remove(state, action) {
            delete state.items[action.payload];
        },
    },
});

export { actions as commandsActions };
export { reducer as commandsReducer };
