import {
    amber, grey, green, indigo, red, common,
} from '@mui/material/colors';

const colors = {
    white: common.white,
    background: grey[50],
    primary: indigo[900],
    secondary: green[500],
    positive: green[500],
    medium: amber[700],
    negative: red[500],
    neutral: grey[500],
    geometry: '#3bb2d0',
};

export default {
    colors,
};
