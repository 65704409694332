import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Table, TableRow, TableCell, TableHead, TableBody,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useEffectAsync } from '../reactHelper';
import { useTranslation } from '../common/components/LocalizationProvider';
import { useManager } from '../common/util/permissions';
import PageLayout from '../common/components/PageLayout';
import SettingsMenu from './components/SettingsMenu';
import CollectionFab from './components/CollectionFab';
import CollectionActions from './components/CollectionActions';
import TableShimmer from '../common/components/TableShimmer';
import SearchHeader, { filterByKeyword } from './components/SearchHeader';
import { attributesActions } from '../store';

const useStyles = makeStyles((theme) => ({
    columnAction: {
        width: '1%',
        paddingRight: theme.spacing(1),
    },
}));

const ComputedAttributesPage = () => {
    const classes = useStyles();
    const t = useTranslation();
    const dispatch = useDispatch();
    const items = useSelector((state) => state.attributes.items);
    const loaded = useSelector((state) => state.attributes.loaded);
    const [searchKeyword, setSearchKeyword] = useState('');
    const manager = useManager();

    useEffectAsync(async () => {
        if (!loaded) {
            try {
                const response = await fetch('/api/tracking/attributes/computed');
                if (response.ok) {
                    const result = await response.json();
                    dispatch(attributesActions.update(result));
                } else {
                    throw Error(await response.text());
                }
            } catch {
                throw Error('Error');
            }
        }
    }, []);

    return (
        <PageLayout menu={<SettingsMenu />} breadcrumbs={['settingsTitle', 'sharedComputedAttributes']}>
            <SearchHeader keyword={searchKeyword} setKeyword={setSearchKeyword} />
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>{t('sharedDescription')}</TableCell>
                        <TableCell>{t('sharedAttribute')}</TableCell>
                        <TableCell>{t('sharedExpression')}</TableCell>
                        <TableCell>{t('sharedType')}</TableCell>
                        {manager && <TableCell className={classes.columnAction} />}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {loaded ? Object.values(items).filter(filterByKeyword(searchKeyword)).map((item) => (
                        <TableRow key={item.id}>
                            <TableCell>{item.description}</TableCell>
                            <TableCell>{item.name}</TableCell>
                            <TableCell>{item.expression}</TableCell>
                            <TableCell>{item.type}</TableCell>
                            {manager && (
                                <TableCell className={classes.columnAction} padding="none">
                                    <CollectionActions
                                        itemId={item.id}
                                        editPath="/settings/attribute"
                                        endpoint="attributes/computed"
                                        removeAction={attributesActions.remove}
                                    />
                                </TableCell>
                            )}
                        </TableRow>
                    )) : (<TableShimmer columns={manager ? 5 : 4} endAction={manager} />)}
                </TableBody>
            </Table>
            <CollectionFab editPath="/settings/attribute" disabled={!manager} />
        </PageLayout>
    );
};

export default ComputedAttributesPage;
