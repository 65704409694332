import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import TextField from '@mui/material/TextField';
import {
    Accordion, AccordionSummary, AccordionDetails, Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditItemView from './components/EditItemView';
import EditAttributesAccordion from './components/EditAttributesAccordion';
import { useTranslation } from '../common/components/LocalizationProvider';
import SettingsMenu from './components/SettingsMenu';
import { driversActions } from '../store';

const useStyles = makeStyles((theme) => ({
    details: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        paddingBottom: theme.spacing(3),
    },
}));

const DriverPage = () => {
    const classes = useStyles();
    const t = useTranslation();
    const dispatch = useDispatch();
    const [item, setItem] = useState();
    const onItemSaved = (result) => {
        dispatch(driversActions.update([result]));
    };
    const validate = () => item && item.name && item.uniqueId;

    return (
        <EditItemView
            endpoint="drivers"
            item={item}
            setItem={setItem}
            validate={validate}
            onItemSaved={onItemSaved}
            menu={<SettingsMenu />}
            breadcrumbs={['settingsTitle', 'sharedDriver']}
        >
            {item && (
                <>
                    <Accordion defaultExpanded>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="subtitle1">
                                {t('sharedRequired')}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.details}>
                            <TextField
                                value={item.name || ''}
                                onChange={(event) => setItem({ ...item, name: event.target.value })}
                                label={t('sharedName')}
                            />
                            <TextField
                                value={item.uniqueId || ''}
                                onChange={(event) => setItem({ ...item, uniqueId: event.target.value })}
                                label={t('deviceIdentifier')}
                            />
                        </AccordionDetails>
                    </Accordion>
                    <EditAttributesAccordion
                        attributes={item.attributes}
                        setAttributes={(attributes) => setItem({ ...item, attributes })}
                        definitions={{}}
                    />
                </>
            )}
        </EditItemView>
    );
};

export default DriverPage;
