import React from 'react';
import {
    Divider, List, ListItemButton, ListItemIcon, ListItemText,
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
//import CreateIcon from '@mui/icons-material/Create';
import NotificationsIcon from '@mui/icons-material/Notifications';
//import FolderIcon from '@mui/icons-material/Folder';
import PersonIcon from '@mui/icons-material/Person';
import FunctionsIcon from '@mui/icons-material/Functions';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
//import BuildIcon from '@mui/icons-material/Build';
import PeopleIcon from '@mui/icons-material/People';
import TodayIcon from '@mui/icons-material/Today';
import PublishIcon from '@mui/icons-material/Publish';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from '../../common/components/LocalizationProvider';
import { useManager, useRestriction } from '../../common/util/permissions';
import useFeatures from '../../common/util/useFeatures';

const MenuItem = ({
    title, link, icon, selectedIcon, selected,
}) => (
    <ListItemButton key={link} component={Link} to={link} selected={selected}>
        <ListItemIcon>{selected && selectedIcon ? selectedIcon : icon}</ListItemIcon>
        <ListItemText primary={title} />
    </ListItemButton>
);

const SettingsMenu = () => {
    const t = useTranslation();
    const location = useLocation();

    const readonly = useRestriction('readonly');
    const manager = useManager();
    const userId = useSelector((state) => state.session.user.id);

    const features = useFeatures();

    return (
        <>
            <List>
                <MenuItem
                    title={t('sharedPreferences')}
                    link="/settings/preferences"
                    icon={<SettingsIcon />}
                    selectedIcon={<SettingsIcon color="primary" />}
                    selected={location.pathname === '/settings/preferences'}
                />
            </List>
            <Divider />
            <List>
                { (manager || !readonly) && (
                    <>
                        <MenuItem
                            title={t('settingsUser')}
                            link={`/settings/user/${userId}`}
                            icon={<ManageAccountsIcon />}
                            selectedIcon={<ManageAccountsIcon color="primary" />}
                            selected={location.pathname === `/settings/user/${userId}`}
                        />
                        {manager && (
                                    <MenuItem
                                        title={t('settingsUsers')}
                                        link="/settings/users"
                                        icon={<PeopleIcon />}
                                        selectedIcon={<PeopleIcon color="primary" />}
                                        selected={location.pathname.startsWith('/settings/user') && location.pathname !== `/settings/user/${userId}`}
                                    />
                        )}
                        {!features.disableGroups && (
                            <MenuItem
                                title={t('settingsGroups')}
                                link="/settings/groups"
                                icon={<AccountBalanceIcon />}
                                selectedIcon={<AccountBalanceIcon color="primary" />}
                                selected={location.pathname.startsWith('/settings/group')}
                            />
                        )}
                        {!features.disableDrivers && (
                            <MenuItem
                                title={t('sharedDrivers')}
                                link="/settings/drivers"
                                icon={<PersonIcon />}
                                selectedIcon={<PersonIcon color="primary" />}
                                selected={location.pathname.startsWith('/settings/driver')}
                            />
                        )}
                        <MenuItem
                            title={t('sharedSavedCommands')}
                            link="/settings/commands"
                            icon={<PublishIcon />}
                            selectedIcon={<PublishIcon color="primary" />}
                            selected={location.pathname.startsWith('/settings/command')}
                        />
                        <MenuItem
                            title={t('sharedNotifications')}
                            link="/settings/notifications"
                            icon={<NotificationsIcon />}
                            selectedIcon={<NotificationsIcon color="primary" />}
                            selected={location.pathname.startsWith('/settings/notification')}
                        />
                        {/* <MenuItem
              title={t('sharedGeofences')}
              link="/geofences"
              icon={<CreateIcon />}
              selected={location.pathname.startsWith('/settings/geofence')}
            />*/}
                        {!features.disableCalendars && (
                            <MenuItem
                                title={t('sharedCalendars')}
                                link="/settings/calendars"
                                icon={<TodayIcon />}
                                selectedIcon={<TodayIcon color="primary" />}
                                selected={location.pathname.startsWith('/settings/calendar')}
                            />
                        )}
                        {!features.disableComputedAttributes && (
                            <MenuItem
                                title={t('sharedComputedAttributes')}
                                link="/settings/attributes"
                                icon={<FunctionsIcon />}
                                selectedIcon={<FunctionsIcon color="primary" />}
                                selected={location.pathname.startsWith('/settings/attribute')}
                            />
                        )}
                        {/*
              <MenuItem
                title={t('sharedMaintenance')}
                link="/settings/maintenances"
                icon={<BuildIcon />}
                selected={location.pathname.startsWith('/settings/maintenance')}
              />*/}
                    </>
                )}
            </List>
        </>
    );
};

export default SettingsMenu;
