import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import {
    IconButton, Tooltip, Avatar, ListItemAvatar, ListItemText, ListItemButton,
} from '@mui/material';
import HttpsIcon from '@mui/icons-material/Https';
import ErrorIcon from '@mui/icons-material/Error';
import moment from 'moment';
import { devicesActions } from '../store';
import {
    formatAlarm, formatSpeed, formatBoolean, formatStatus, getStatusColor,
} from '../common/util/formatter';
import { useTranslation } from '../common/components/LocalizationProvider';
import { mapIconKey, mapIcons } from '../map/core/preloadImages';
import usePersistedState from '../common/util/usePersistedState';
import { ReactComponent as EngineIcon } from '../resources/images/data/engine.svg';
import palette from '../common/theme/palette';

const useStyles = makeStyles((theme) => ({
    icon: {
        width: '27px',
        height: '27px',
        filter: 'brightness(0) invert(1)',
    },
    batteryText: {
        fontSize: '0.75rem',
        fontWeight: 'normal',
        lineHeight: '0.875rem',
    },
    listItemStatus: {
        position: 'relative',
        height: '60px',
        width: '90px',
    },
    listItemStatusSpeed: {
        position: 'absolute',
        top: '0',
        right: '0',
        width: '100%',
        textAlign: 'center',
        fontSize: '14px',
    },
    listItemStatusIo: {
        position: 'absolute',
        bottom: '0',
        right: '0',
        width: '100%',
        height: '60%',
        textAlign: 'center',
    },
    positive: {
        color: theme.palette.colors.positive,
    },
    medium: {
        color: theme.palette.colors.medium,
    },
    negative: {
        color: theme.palette.colors.negative,
    },
    neutral: {
        color: theme.palette.colors.neutral,
    },
}));

const PositionStatus = ({ position, classes, t }) => (
    <div className={classes.listItemStatus}>
        <div className={classes.listItemStatusSpeed}>
            {formatSpeed(position.speed, '', t)}
        </div>
        <div className={classes.listItemStatusIo}>
            {position.attributes.hasOwnProperty('alarm') && (
                <Tooltip title={`${t('eventAlarm')}: ${formatAlarm(position.attributes.alarm, t)}`}>
                    <IconButton size="small">
                        <ErrorIcon fontSize="small" className={classes.negative} />
                    </IconButton>
                </Tooltip>
            )}
            {!!position.attributes.ignition && (
                <Tooltip title={`${t('positionIgnition')}: ${formatBoolean(position.attributes.ignition, t)}`}>
                    <IconButton size="small">
                        <EngineIcon width={24} height={24} className={classes.positive} />
                    </IconButton>
                </Tooltip>
            )}
            {!!position.attributes.blocked && (
                <Tooltip title={`${t('positionBlocked')}: ${formatBoolean(position.attributes.blocked, t)}`}>
                    <IconButton size="small">
                        <HttpsIcon fontSize="meduim" className={classes.negative} />
                    </IconButton>
                </Tooltip>
            )}
        </div>
    </div>
);
const DeviceRow = ({ data, index, style }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const t = useTranslation();

    const item = data[index];
    const position = useSelector((state) => state.positions.items[item.id]);

    const geofences = useSelector((state) => state.geofences.items);

    const [devicePrimary] = usePersistedState('devicePrimary', 'name');
    const [deviceSecondary] = usePersistedState('deviceSecondary', '');

    const formatProperty = (key) => {
        if (key === 'geofenceIds') {
            const geofenceIds = item[key] || [];
            return geofenceIds
                .filter((id) => geofences.hasOwnProperty(id))
                .map((id) => geofences[id].name)
                .join(', ');
        }
        return item[key];
    };

    const secondaryText = () => {
        let status = '';
        if (position) {
            status = moment(position.fixTime).fromNow();
        } else {
            status = formatStatus(item.status, t);
        }
        return (
            <>
                {deviceSecondary && item[deviceSecondary] && `${formatProperty(deviceSecondary)} • `}
                <span className={classes[getStatusColor(item.status)]}>{status}</span>
            </>
        );
    };

    return (
        <div style={style}>
            <ListItemButton
                key={item.id}
                onClick={() => dispatch(devicesActions.select(item.id))}
                disabled={!position}
            >
                <ListItemAvatar>
                    <Avatar sx={{
                        bgcolor:
                            (item.status === 'online' ? palette.colors.positive : palette.colors.negative),
                    }}
                    >
                        <img className={classes.icon} src={mapIcons[mapIconKey(item.category)]} alt="" />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    primary={formatProperty(devicePrimary)}
                    primaryTypographyProps={{ noWrap: true }}
                    secondary={secondaryText()}
                    secondaryTypographyProps={{ noWrap: true }}
                />
                {position && (
                    <PositionStatus position={position} classes={classes} t={t} />
                )}
            </ListItemButton>
        </div>
    );
};

export default DeviceRow;
