import React, { useState } from 'react';

import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
//import FormControlLabel from '@mui/material/FormControlLabel';
//import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Typography from '@mui/material/Typography';
import { ThemeProvider } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import theme from '../common/theme';
import { useTranslation } from '../common/components/LocalizationProvider';
import { sessionActions } from '../store';
import usePersistedState from '../common/util/usePersistedState';

const Login = () => {
    const dispatch = useDispatch();
    const t = useTranslation();
    const navigate = useNavigate();
    const [login, setLogin] = usePersistedState('loginUserName', '');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    //const [rememberMe, setRememberMe] = useState(false);
    const [failed, setFailed] = useState(false);

    const toggleShowPassword = (event) => {
        event.preventDefault();
        setShowPassword(!showPassword);
    };

    const handlePasswordLogin = async (event) => {
        event.preventDefault();
        try {
            const response = await fetch('/api/tracking/session', {
                method: 'POST',
                body: new URLSearchParams(`login=${encodeURIComponent(login)}&password=${encodeURIComponent(password)}&isMobile=true`),
            });
            if (response.ok) {
                const user = await response.json();
                dispatch(sessionActions.updateUser(user));
                navigate('/');
            } else {
                throw Error(await response.text());
            }
        } catch (error) {
            setFailed(true);
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <Grid container component="main" sx={{ height: '100vh' }}>
                <CssBaseline />
                <Grid
                    item
                    xs={false}
                    sm={4}
                    md={7}
                    sx={{
                        backgroundImage: 'url(./images/bg.jpg)',
                        backgroundRepeat: 'no-repeat',
                        backgroundColor: (t) => (t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900]),
                        backgroundSize: 'cover',
                    }}
                />
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                    <Box
                        sx={{
                            my: 8,
                            mx: 4,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <img alt="" src="logo192.png" width="128" />
                        <Typography component="h1" variant="h5" color="text.secondary">
                            Fleet Is Safe
                        </Typography>
                        <Box component="form" noValidate onSubmit={handlePasswordLogin} sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="login"
                                label={t('userUserName')} // login
                                name="login"
                                value={login}
                                autoFocus={!login}
                                onChange={(e) => setLogin(e.target.value)}
                                helperText={failed && t('loginFailed')}// loginFailed
                                error={failed}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label={t('userPassword')}
                                type={showPassword ? 'text' : 'password'} // <-- This is where the magic happens
                                id="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                autoComplete="current-password"
                                error={failed}
                                InputProps={{ // <-- This is where the toggle button is added.
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={toggleShowPassword}
                                                onMouseDown={toggleShowPassword}
                                            >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            {/* <FormControlLabel
                                control={(
                                    <Checkbox
                                        checked={rememberMe}
                                        onChange={(e) => setRememberMe(e.target.checked)}
                                        value="remember"
                                        color="primary"
                                    />
                                )}
                                label={t('loginRememberMe')}
                            />*/}
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                disabled={!login || !password}
                                sx={{ mt: 3, mb: 2 }}
                            >
                                {t('loginLogin')}
                            </Button>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </ThemeProvider>
    );
};
export default Login;
