import { combineReducers, configureStore } from '@reduxjs/toolkit';

import { errorsReducer as errors } from './errors';
import { sessionReducer as session } from './session';
import { devicesReducer as devices } from './devices';
import { positionsReducer as positions } from './positions';
import { eventsReducer as events } from './events';
import { geofencesReducer as geofences } from './geofences';
import { groupsReducer as groups } from './groups';
import { driversReducer as drivers } from './drivers';
import { maintenancesReducer as maintenances } from './maintenances';
import { reportsReducer as reports } from './reports';
import { usersReducer as users } from './users';
import { notificationsReducer as notifications } from './notifications';
import { calendarsReducer as calendars } from './calendars';
import { attributesReducer as attributes } from './attributes';
import { commandsReducer as commands } from './commands';
import throttleMiddleware from './throttleMiddleware';

const combinedReducer = combineReducers({
    errors,
    session,
    devices,
    positions,
    events,
    geofences,
    groups,
    drivers,
    maintenances,
    reports,
    users,
    notifications,
    calendars,
    attributes,
    commands,
});
const rootReducer = (state, action) => {
    if (action.type === 'session/updateUser'
        && action.payload === null) { // check for action type and reset store if user logged out
        state = undefined;
    }
    return combinedReducer(state, action);
};
export { errorsActions } from './errors';
export { sessionActions } from './session';
export { devicesActions } from './devices';
export { positionsActions } from './positions';
export { eventsActions } from './events';
export { geofencesActions } from './geofences';
export { groupsActions } from './groups';
export { driversActions } from './drivers';
export { maintenancesActions } from './maintenances';
export { reportsActions } from './reports';
export { usersActions } from './users';
export { notificationsActions } from './notifications';
export { calendarsActions } from './calendars';
export { attributesActions } from './attributes';
export { commandsActions } from './commands';

export default configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(throttleMiddleware),
});
