import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Table, TableRow, TableCell, TableHead, TableBody,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useEffectAsync } from '../reactHelper';
import { prefixString } from '../common/util/stringUtils';
import { formatBoolean } from '../common/util/formatter';
import { useTranslation } from '../common/components/LocalizationProvider';
import PageLayout from '../common/components/PageLayout';
import SettingsMenu from './components/SettingsMenu';
import CollectionFab from './components/CollectionFab';
import CollectionActions from './components/CollectionActions';
import TableShimmer from '../common/components/TableShimmer';
import SearchHeader, { filterByKeyword } from './components/SearchHeader';
import { notificationsActions } from '../store';

const useStyles = makeStyles((theme) => ({
    columnAction: {
        width: '1%',
        paddingRight: theme.spacing(1),
    },
}));

const NotificationsPage = () => {
    const classes = useStyles();
    const t = useTranslation();
    const dispatch = useDispatch();
    const items = useSelector((state) => state.notifications.items);
    const loaded = useSelector((state) => state.notifications.loaded);
    const [searchKeyword, setSearchKeyword] = useState('');

    useEffectAsync(async () => {
        if (!loaded) {
            try {
                const response = await fetch('/api/tracking/notifications');
                if (response.ok) {
                    const result = await response.json();
                    dispatch(notificationsActions.update(result));
                } else {
                    throw Error(await response.text());
                }
            } catch {
                throw Error('Error');
            }
        }
    }, []);

    const formatList = (prefix, value) => {
        if (value) {
            return value
                .split(/[, ]+/)
                .filter(Boolean)
                .map((it) => t(prefixString(prefix, it)))
                .join(', ');
        }
        return '';
    };

    return (
        <PageLayout menu={<SettingsMenu />} breadcrumbs={['settingsTitle', 'sharedNotifications']}>
            <SearchHeader keyword={searchKeyword} setKeyword={setSearchKeyword} />
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>{t('notificationType')}</TableCell>
                        <TableCell>{t('notificationAlways')}</TableCell>
                        <TableCell>{t('notificationNotificators')}</TableCell>
                        <TableCell className={classes.columnAction} />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {loaded ? Object.values(items).filter(filterByKeyword(searchKeyword)).map((item) => (
                        <TableRow key={item.id}>
                            <TableCell>{t(prefixString('event', item.type))}</TableCell>
                            <TableCell>{formatBoolean(item.always, t)}</TableCell>
                            <TableCell>{formatList('notificator', item.notificators)}</TableCell>
                            <TableCell className={classes.columnAction} padding="none">
                                <CollectionActions
                                    itemId={item.id}
                                    editPath="/settings/notification"
                                    endpoint="notifications"
                                    removeAction={notificationsActions.remove}
                                />
                            </TableCell>
                        </TableRow>
                    )) : (<TableShimmer columns={4} endAction />)}
                </TableBody>
            </Table>
            <CollectionFab editPath="/settings/notification" />
        </PageLayout>
    );
};

export default NotificationsPage;
