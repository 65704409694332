import { useMemo } from 'react';

export default (t) => useMemo(() => ({
  notificationTokens: {
    name: t('attributeNotificationTokens'),
    type: 'string',
    },
    'mail.support': {
        name: t('attributeMailSupport'),
        type: 'string',
    },
    mapUrl: {
        name: t('mapCustomLabel'),
        type: 'string',
    },
}), [t]);
