import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Accordion, AccordionSummary, AccordionDetails, Typography, TextField,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditItemView from './components/EditItemView';
import SelectField from '../common/components/SelectField';
import { prefixString } from '../common/util/stringUtils';
import { useTranslation } from '../common/components/LocalizationProvider';
//import BaseCommandView from './components/BaseCommandView';
import SettingsMenu from './components/SettingsMenu';
import { useEffectAsync } from '../reactHelper';
import { commandsActions } from '../store';

const useStyles = makeStyles((theme) => ({
    details: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        paddingBottom: theme.spacing(3),
    },
}));

const CommandPage = () => {
    const classes = useStyles();
    const t = useTranslation();
    const dispatch = useDispatch();
    const [item, setItem] = useState();
    const types = useSelector((state) => state.commands.types);
    const typesLoaded = useSelector((state) => state.commands.typesLoaded);

    const onItemSaved = (result) => {
        dispatch(commandsActions.update([result]));
    };
    const validate = () => item && item.type;

    useEffectAsync(async () => {
        if (!typesLoaded) {
            try {
                const response = await fetch('/api/tracking/commands/types');
                if (response.ok) {
                    const result = await response.json();
                    dispatch(commandsActions.updateTypes(result));
                } else {
                    throw Error(await response.text());
                }
            } catch {
                throw Error('Error');
            }
        }
    }, []);
    return (
        <EditItemView
            endpoint="commands"
            item={item}
            setItem={setItem}
            validate={validate}
            onItemSaved={onItemSaved}
            menu={<SettingsMenu />}
            breadcrumbs={['settingsTitle', 'sharedSavedCommand']}
        >
            {typesLoaded && item && (
                <Accordion defaultExpanded>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="subtitle1">
                            {t('sharedRequired')}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.details}>
                        <SelectField
                            value={item.type || ''}
                            onChange={(e) => setItem({ ...item, type: e.target.value, attributes: {} })}
                            data={types}
                            keyGetter={(it) => it.type}
                            titleGetter={(it) => t(prefixString('command', it.type))}
                            label={t('sharedType')}
                        />
                        <TextField
                            value={item.description || ''}
                            onChange={(event) => setItem({ ...item, description: event.target.value })}
                            label={t('sharedDescription')}
                        />
                        {/*<BaseCommandView item={item} setItem={setItem} />*/}
                    </AccordionDetails>
                </Accordion>
            )}
        </EditItemView>
    );
};

export default CommandPage;
