import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    FormControlLabel,
    Checkbox,
    FormGroup,
    TextField,
    InputAdornment,
    IconButton,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useDispatch, useSelector } from 'react-redux';
import EditItemView from './components/EditItemView';
import EditAttributesAccordion from './components/EditAttributesAccordion';
import LinkField from '../common/components/LinkField';
import { useTranslation } from '../common/components/LocalizationProvider';
import useUserAttributes from '../common/attributes/useUserAttributes';
import { sessionActions, usersActions } from '../store';
import SettingsMenu from './components/SettingsMenu';
import useCommonUserAttributes from '../common/attributes/useCommonUserAttributes';
import { useManager } from '../common/util/permissions';
import useQuery from '../common/util/useQuery';
import { formatNotificationTitle } from '../common/util/formatter';
import { prefixString } from '../common/util/stringUtils';

const useStyles = makeStyles((theme) => ({
    details: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        paddingBottom: theme.spacing(3),
    },
}));

const UserPage = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const t = useTranslation();
    const manager = useManager();

    const currentUser = useSelector((state) => state.session.user);

    const commonUserAttributes = useCommonUserAttributes(t);
    const userAttributes = useUserAttributes(t);

    const { id } = useParams();
    const [item, setItem] = useState(id === currentUser.id.toString() ? currentUser : null);
    const [showPassword, setShowPassword] = useState(false);

    const query = useQuery();
    const [queryHandled, setQueryHandled] = useState(false);
    const attribute = query.get('attribute');

    useEffect(() => {
        if (!queryHandled && item && attribute) {
            if (!item.attributes.hasOwnProperty('attribute')) {
                const updatedAttributes = { ...item.attributes };
                updatedAttributes[attribute] = '';
                setItem({ ...item, attributes: updatedAttributes });
            }
            setQueryHandled(true);
        }
    }, [item, queryHandled, setQueryHandled, attribute]);

    const onItemSaved = (result) => {
        if (result.id === currentUser.id) {
            dispatch(sessionActions.updateUser(result));
        } else {
            dispatch(usersActions.update([result]));
        }
    };
    const toggleShowPassword = (event) => {
        event.preventDefault();
        setShowPassword(!showPassword);
    };
    const validate = () => item && item.name && item.login && (item.id || item.password);

    return (
        <EditItemView
            endpoint="users"
            item={item}
            setItem={setItem}
            defaultItem={{ manager: false, language: 'fr' }}
            validate={validate}
            onItemSaved={onItemSaved}
            menu={<SettingsMenu />}
            breadcrumbs={['settingsTitle', 'settingsUser']}
        >
            {item && (
                <>
                    <Accordion defaultExpanded={!attribute}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="subtitle1">
                                {t('sharedRequired')}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.details}>
                            <TextField
                                value={item.name || ''}
                                onChange={(event) => setItem({ ...item, name: event.target.value })}
                                label={t('sharedName')}
                            />
                            <TextField
                                value={item.login || ''}
                                onChange={(event) => setItem({ ...item, login: event.target.value })}
                                label={t('userUserName')}
                                disabled={item.id}
                            />
                            <TextField
                                type={showPassword ? 'text' : 'password'}
                                value={item.password || ''}
                                onChange={(event) => setItem({ ...item, password: event.target.value })}
                                label={t('userPassword')}
                                InputProps={{ // <-- This is where the toggle button is added.
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={toggleShowPassword}
                                                onMouseDown={toggleShowPassword}
                                            >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="subtitle1">
                                {t('deviceContact')}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.details}>
                            <TextField
                                value={item.phone || ''}
                                onChange={(event) => setItem({ ...item, phone: event.target.value })}
                                label={t('sharedPhone')}
                            />
                            <TextField
                                value={item.email || ''}
                                onChange={(event) => setItem({ ...item, email: event.target.value })}
                                label={t('userEmail')}
                            />
                            <TextField
                                value={item.address || ''}
                                onChange={(event) => setItem({ ...item, address: event.target.value })}
                                label={t('positionAddress')}
                            />
                        </AccordionDetails>
                    </Accordion>
                    <EditAttributesAccordion
                        attribute={attribute}
                        attributes={item.attributes}
                        setAttributes={(attributes) => setItem({ ...item, attributes })}
                        definitions={{ ...commonUserAttributes, ...userAttributes }}
                        focusAttribute={attribute}
                    />
                    {manager && item.id !== currentUser.id && (
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="subtitle1">
                                    {t('sharedPermissions')}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails className={classes.details}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Checkbox checked={item.disabled} onChange={(e) => setItem({ ...item, disabled: e.target.checked })} />}
                                        label={t('sharedDisabled')}
                                    />
                                    <FormControlLabel
                                        control={<Checkbox checked={item.readonly} onChange={(e) => setItem({ ...item, readonly: e.target.checked })} />}
                                        label={t('serverReadonly')}
                                    />
                                    <FormControlLabel
                                        control={<Checkbox checked={item.deviceReadonly} onChange={(e) => setItem({ ...item, deviceReadonly: e.target.checked })} />}
                                        label={t('userDeviceReadonly')}
                                    />
                                    <FormControlLabel
                                        control={<Checkbox checked={item.limitCommands} onChange={(e) => setItem({ ...item, limitCommands: e.target.checked })} />}
                                        label={t('userLimitCommands')}
                                    />
                                </FormGroup>
                            </AccordionDetails>
                        </Accordion>
                    )}
                    {item.id && manager && item.id !== currentUser.id && (
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="subtitle1">
                                    {t('sharedConnections')}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails className={classes.details}>
                                <LinkField
                                    endpointAll="/api/tracking/devices"
                                    endpointLinked={`/api/tracking/devices?userId=${item.id}`}
                                    baseId={item.id}
                                    keyBase="userId"
                                    keyLink="deviceId"
                                    label={t('deviceTitle')}
                                />
                                <LinkField
                                    endpointAll="/api/tracking/groups"
                                    endpointLinked={`/api/tracking/groups?userId=${item.id}`}
                                    baseId={item.id}
                                    keyBase="userId"
                                    keyLink="groupId"
                                    label={t('settingsGroups')}
                                />
                                {/* <LinkField
                                    endpointAll="/api/geofences?all=true"
                                    endpointLinked={`/api/geofences?userId=${item.id}`}
                                    baseId={item.id}
                                    keyBase="userId"
                                    keyLink="geofenceId"
                                    label={t('sharedGeofences')}
                                />*/}
                                <LinkField
                                    endpointAll="/api/tracking/notifications"
                                    endpointLinked={`/api/tracking/notifications?userId=${item.id}`}
                                    baseId={item.id}
                                    keyBase="userId"
                                    keyLink="notificationId"
                                    titleGetter={(it) => formatNotificationTitle(t, it, true)}
                                    label={t('sharedNotifications')}
                                />
                                <LinkField
                                    endpointAll="/api/tracking/calendars"
                                    endpointLinked={`/api/tracking/calendars?userId=${item.id}`}
                                    baseId={item.id}
                                    keyBase="userId"
                                    keyLink="calendarId"
                                    label={t('sharedCalendars')}
                                />
                                <LinkField
                                    endpointAll="/api/tracking/attributes/computed"
                                    endpointLinked={`/api/tracking/attributes/computed?userId=${item.id}`}
                                    baseId={item.id}
                                    keyBase="userId"
                                    keyLink="attributeId"
                                    titleGetter={(it) => it.description}
                                    label={t('sharedComputedAttributes')}
                                />
                                <LinkField
                                    endpointAll="/api/tracking/drivers"
                                    endpointLinked={`/api/tracking/drivers?userId=${item.id}`}
                                    baseId={item.id}
                                    keyBase="userId"
                                    keyLink="driverId"
                                    label={t('sharedDrivers')}
                                />
                                <LinkField
                                    endpointAll="/api/tracking/commands"
                                    endpointLinked={`/api/tracking/commands?userId=${item.id}`}
                                    baseId={item.id}
                                    keyBase="userId"
                                    keyLink="commandId"
                                    titleGetter={(it) => t(prefixString('command', it.type))}
                                    label={t('sharedSavedCommands')}
                                />
                                {/* <LinkField
                                    endpointAll="/api/maintenance?all=true"
                                    endpointLinked={`/api/maintenance?userId=${item.id}`}
                                    baseId={item.id}
                                    keyBase="userId"
                                    keyLink="maintenanceId"
                                    label={t('sharedMaintenance')}
                                />*/}
                            </AccordionDetails>
                        </Accordion>
                    )}
                </>
            )}
        </EditItemView>
    );
};

export default UserPage;
