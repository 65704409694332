import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import {
    Paper, BottomNavigation, BottomNavigationAction, Badge,
} from '@mui/material';

import DescriptionIcon from '@mui/icons-material/Description';
import SettingsIcon from '@mui/icons-material/Settings';
import MapIcon from '@mui/icons-material/Map';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

import { sessionActions } from '../../store';
import { useTranslation } from './LocalizationProvider';
import { useRestriction } from '../util/permissions';
import { nativePostMessage } from './NativeInterface';

const BottomMenu = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const t = useTranslation();

    const readonly = useRestriction('readonly');
    const disableReports = useRestriction('disableReports');
    const socket = useSelector((state) => state.session.socket);

    const currentSelection = () => {
        if (location.pathname.startsWith('/settings')) {
            return 'settings';
        } if (location.pathname.startsWith('/reports')) {
            return 'reports';
        } if (location.pathname === '/') {
            return 'map';
        }
        return null;
    };

    const handleLogout = async () => {
        await fetch('/api/tracking/session', { method: 'DELETE' });
        nativePostMessage('logout');
        // navigate('/login');
        dispatch(sessionActions.updateUser(null));
    };

    const handleSelection = (event, value) => {
        switch (value) {
            case 'map':
                navigate('/');
                break;
            case 'reports':
                navigate('/reports/route');
                break;
            case 'settings':
                navigate('/settings/preferences');
                break;
            case 'logout':
                handleLogout();
                break;
            default:
                break;
        }
    };

    return (
        <Paper square elevation={3}>
            <BottomNavigation value={currentSelection()} onChange={handleSelection} showLabels>
                <BottomNavigationAction
                    label={t('mapTitle')}
                    icon={(
                        <Badge color="error" variant="dot" overlap="circular" invisible={socket !== false}>
                            <MapIcon />
                        </Badge>
                    )}
                    value="map"
                />
                {!disableReports && (
                    <BottomNavigationAction label={t('reportTitle')} icon={<DescriptionIcon />} value="reports" />
                )}
                {!readonly && (
                    <BottomNavigationAction label={t('settingsTitle')} icon={<SettingsIcon />} value="settings" />
                )}
                <BottomNavigationAction label={t('loginLogout')} icon={<ExitToAppIcon />} value="logout" />
            </BottomNavigation>
        </Paper>
    );
};

export default BottomMenu;
