import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Draggable from 'react-draggable';
import {
    Card,
    CardContent,
    Typography,
    CardActions,
    IconButton,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Menu,
    MenuItem,
    CardMedia,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from '@mui/icons-material/Close';
import ReplayIcon from '@mui/icons-material/Replay';
import PublishIcon from '@mui/icons-material/Publish';
import EditIcon from '@mui/icons-material/Edit';
import PendingIcon from '@mui/icons-material/Pending';

import { useTranslation } from './LocalizationProvider';
import PositionValue from './PositionValue';
import { useDeviceReadonly, useLimitCommands } from '../util/permissions';
import usePersistedState from '../util/usePersistedState';
import usePositionAttributes from '../attributes/usePositionAttributes';
//import { useCatchCallback } from '../../reactHelper';

const useStyles = makeStyles((theme) => ({
    card: {
        width: theme.dimensions.popupMaxWidth,
    },
    media: {
        height: theme.dimensions.popupImageHeight,
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-start',
    },
    mediaButton: {
        color: theme.palette.colors.white,
        mixBlendMode: 'difference',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: theme.spacing(1, 1, 0, 2),
    },
    content: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    negative: {
        color: theme.palette.colors.negative,
    },
    icon: {
        width: '25px',
        height: '25px',
        filter: 'brightness(0) invert(1)',
    },
    table: {
        '& .MuiTableCell-sizeSmall': {
            paddingLeft: 0,
            paddingRight: 0,
        },
    },
    cell: {
        borderBottom: 'none',
    },
    actions: {
        justifyContent: 'space-between',
    },
    root: ({ desktopPadding }) => ({
        position: 'fixed',
        zIndex: 5,
        left: '50%',
        [theme.breakpoints.up('md')]: {
            left: `calc(50% + ${desktopPadding} / 2)`,
            bottom: theme.spacing(3),
        },
        [theme.breakpoints.down('md')]: {
            left: '50%',
            bottom: `calc(${theme.spacing(3)} + ${theme.dimensions.bottomBarHeight}px)`,
        },
        transform: 'translateX(-50%)',
    }),
}));

const StatusRow = ({ name, content }) => {
    const classes = useStyles();

    return (
        <TableRow>
            <TableCell className={classes.cell}>
                <Typography variant="body2">{name}</Typography>
            </TableCell>
            <TableCell className={classes.cell}>
                <Typography variant="body2" color="textSecondary">{content}</Typography>
            </TableCell>
        </TableRow>
    );
};

const StatusCard = ({ deviceId, position, onClose, disableActions, desktopPadding = 0 }) => {
    const classes = useStyles({ desktopPadding });
    const navigate = useNavigate();
    const t = useTranslation();
    const deviceReadonly = useDeviceReadonly();
    const limitCommands = useLimitCommands();

    const device = useSelector((state) => state.devices.items[deviceId]);

    const deviceImage = device?.attributes?.deviceImage;

    const positionAttributes = usePositionAttributes(t);
    const [positionItems] = usePersistedState('positionItems', ['fixTime', 'speed', 'totalDistance', 'address']);

    const [anchorEl, setAnchorEl] = useState(null);

   /* const handleGeofence = useCatchCallback(async () => {
        const newItem = {
            name: '',
            area: `CIRCLE (${position.latitude} ${position.longitude}, 50)`,
        };
        const response = await fetch('/api/geofences', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(newItem),
        });
        if (response.ok) {
            const item = await response.json();
            const permissionResponse = await fetch('/api/permissions', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ deviceId: position.deviceId, geofenceId: item.id }),
            });
            if (!permissionResponse.ok) {
                throw Error(await permissionResponse.text());
            }
            navigate(`/settings/geofence/${item.id}`);
        } else {
            throw Error(await response.text());
        }
    }, [navigate]);*/

    return (
        <div className={classes.root}>
            {device && (
                <Draggable
                    handle={`.${classes.media}, .${classes.header}`}
                >
                    <Card elevation={3} className={classes.card}>
                        {deviceImage ? (
                            <CardMedia
                                className={classes.media}
                                image={`/api/media/${device.uniqueId}/${deviceImage}`}
                            >
                                <IconButton
                                    size="small"
                                    onClick={onClose}
                                    onTouchStart={onClose}
                                >
                                    <CloseIcon fontSize="small" className={classes.mediaButton} />
                                </IconButton>
                            </CardMedia>
                        ) : (
                            <div className={classes.header}>
                                <Typography variant="body2" color="textSecondary">
                                    {device.name}
                                </Typography>
                                <IconButton
                                    size="small"
                                    onClick={onClose}
                                    onTouchStart={onClose}
                                >
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                            </div>
                        )}
                        {position && (
                            <CardContent className={classes.content}>
                                <Table size="small" classes={{ root: classes.table }}>
                                    <TableBody>
                                        {positionItems.filter((key) => position.hasOwnProperty(key) || position.attributes.hasOwnProperty(key)).map((key) => (
                                            <StatusRow
                                                key={key}
                                                name={positionAttributes[key].name}
                                                content={(
                                                    <PositionValue
                                                        position={position}
                                                        property={position.hasOwnProperty(key) ? key : null}
                                                        attribute={position.hasOwnProperty(key) ? null : key}
                                                    />
                                                )}
                                            />
                                        ))}
                                    </TableBody>
                                </Table>
                            </CardContent>
                        )}
                        <CardActions classes={{ root: classes.actions }} disableSpacing>
                            <IconButton
                                color="secondary"
                                onClick={(e) => setAnchorEl(e.currentTarget)}
                                disabled={!position}
                            >
                                <PendingIcon />
                            </IconButton>
                            <IconButton
                                onClick={() => navigate('/replay')}
                                disabled={disableActions || !position}
                            >
                                <ReplayIcon />
                            </IconButton>
                                <IconButton
                                    onClick={() => navigate(`/settings/command-send/${deviceId}`)}
                                    disabled={disableActions || limitCommands}
                                >
                                    <PublishIcon />
                                </IconButton>
                            <IconButton
                                onClick={() => navigate(`/settings/device/${deviceId}`)}
                                disabled={disableActions || deviceReadonly}
                            >
                                <EditIcon />
                            </IconButton>
                        </CardActions>
                    </Card>
                </Draggable>
            )}
            {position && (
                <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
                    <MenuItem onClick={() => navigate(`/position/${position.deviceId}`)}><Typography color="secondary">{t('sharedShowDetails')}</Typography></MenuItem>
                    {/* <MenuItem onClick={handleGeofence}>{t('sharedCreateGeofence')}</MenuItem>*/}
                    <MenuItem component="a" target="_blank" href={`https://www.google.com/maps/search/?api=1&query=${position.latitude}%2C${position.longitude}`}>{t('linkGoogleMaps')}</MenuItem>
                    <MenuItem component="a" target="_blank" href={`http://maps.apple.com/?ll=${position.latitude},${position.longitude}`}>{t('linkAppleMaps')}</MenuItem>
                    <MenuItem component="a" target="_blank" href={`https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${position.latitude}%2C${position.longitude}&heading=${position.course}`}>{t('linkStreetView')}</MenuItem>
                </Menu>
            )}
        </div>
    );
};

export default StatusCard;
