import React, { useEffect, useState } from 'react';
import { Link } from '@mui/material';
import { useTranslation } from './LocalizationProvider';
import { useCatch } from '../../reactHelper';

const AddressValue = ({ lat, lng, originalAddress }) => {
    const t = useTranslation();

    const addressEnabled = true;// useSelector((state) => state.session.server.geocoderEnabled);

    const [address, setAddress] = useState();

    useEffect(() => {
        setAddress(originalAddress);
    }, [originalAddress]);

    const showAddress = useCatch(async () => {
        const query = new URLSearchParams({ lat, lng });
        const response = await fetch(`/api/tracking/location?${query.toString()}`);
        if (response.ok) {
            const address = await response.json();
            if (address.location) {
                setAddress(address.location);
            }
        } else {
            throw Error(await response.text());
        }
    });

    if (address) {
        return address;
    }
    if (addressEnabled) {
        return (<Link href="#" onClick={showAddress}>{t('sharedShowAddress')}</Link>);
    }
    return '';
};

export default AddressValue;
