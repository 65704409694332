import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Container,
    Button,
    TextField,
    Dialog,
    DialogActions,
    DialogTitle,
    Snackbar,

} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import makeStyles from '@mui/styles/makeStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from '../common/components/LocalizationProvider';
import SelectField from '../common/components/SelectField';
import { prefixString } from '../common/util/stringUtils';
import PageLayout from '../common/components/PageLayout';
import SettingsMenu from './components/SettingsMenu';
import { useCatch } from '../reactHelper';

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: theme.spacing(2),
    },
    buttons: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        display: 'flex',
        justifyContent: 'space-evenly',
        '& > *': {
            flexBasis: '33%',
        },
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        paddingBottom: theme.spacing(3),
    },
}));

const CommandSendPage = () => {
    const navigate = useNavigate();
    const classes = useStyles();
    const t = useTranslation();

    const { deviceId } = useParams();
    const [item, setItem] = useState({});
    const [promptOpen, setPromptOpen] = useState(false);
    const [snackOpen, setSnackOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState({ message: '', severity: '' });

    const handleSend = useCatch(async () => {
        const command = item;

        command.deviceId = parseInt(deviceId, 10);

        const response = await fetch('/api/tracking/commands/send', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(command),
        });

        if (response.ok) {
            if (response.status === 202) {
                setSnackMessage({ message: t('commandQueued'), severity: 'info' });
            } else {
                setSnackMessage({ message: t('commandSent'), severity: 'success' });
            }
            setSnackOpen(true);
            //navigate(-1);
        } else {
            throw Error(await response.text());
        }
    });

    const handlePrompt = useCatch(() => {
        if (item.type === 'engineStop') {
            setPromptOpen(true);
        } else {
            handleSend();
        }
    });
    const handleConfirm = useCatch(() => {
        setPromptOpen(false);
        handleSend();
    });
    const handlePromptClose = useCatch(() => {
            setPromptOpen(false);
    });
    const handleSnackClose = useCatch(() => {
        setSnackOpen(false);
        navigate(-1);
    });
    const validate = () => (item && item.type);

    return (
        <PageLayout menu={<SettingsMenu />} breadcrumbs={['settingsTitle', 'deviceCommand']}>
            <Container maxWidth="xs" className={classes.container}>
                <Accordion defaultExpanded>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="subtitle1">
                            {t('sharedRequired')}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.details}>
                        <SelectField
                            value={item.type || ''}
                            onChange={(e) => setItem({ ...item, type: e.target.value, attributes: {} })}
                            endpoint={`/api/tracking/commands?deviceId=${deviceId}`}
                            keyGetter={(it) => it.type}
                            titleGetter={(it) => t(prefixString('command', it.type))}
                            label={t('sharedType')}
                        />
                        {(item.type === 'custom' || item.type === 'alarmSpeed') && (
                            <TextField
                                type={item.type === 'custom' ? 'text' : 'number'}
                                value={item.attributes.data || ''}
                                onChange={(e) => {
                                    const updateItem = { ...item, attributes: { ...item.attributes } };
                                    updateItem.attributes.data = item.type === 'custom' ? e.target.value : Number(e.target.value);
                                    setItem(updateItem);
                                }}
                                label={t('commandData')}
                            />
                        )}
                    </AccordionDetails>
                </Accordion>
                <div className={classes.buttons}>
                    <Button
                        type="button"
                        color="primary"
                        variant="outlined"
                        onClick={() => navigate(-1)}
                    >
                        {t('sharedCancel')}
                    </Button>
                    <Button
                        type="button"
                        color="primary"
                        variant="contained"
                        onClick={handlePrompt}
                        disabled={!validate()}
                    >
                        {t('commandSend')}
                    </Button>
                </div>
                <Dialog
                    open={promptOpen}
                    onClose={handlePromptClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {t('commandConfirm')}
                    </DialogTitle>
                    <DialogActions>
                        <Button onClick={handlePromptClose}>{t('sharedCancel')}</Button>
                        <Button
                            onClick={handleConfirm}
                            autoFocus
                        >
                            {t('commandSend')}
                        </Button>
                    </DialogActions>
                </Dialog>

                <Snackbar open={snackOpen} autoHideDuration={3000} onClose={handleSnackClose}>
                    <MuiAlert
                        onClose={handleSnackClose}
                        elevation={6}
                        severity={snackMessage.severity}
                        variant="filled"
                        sx={{ width: '100%' }}
                    >
                        {snackMessage.message}
                    </MuiAlert>
                </Snackbar>
            </Container>
        </PageLayout>
    );
};

export default CommandSendPage;
