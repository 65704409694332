import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    FormControlLabel,
    Checkbox,
    FormGroup,
    Button,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from '../common/components/LocalizationProvider';
import EditItemView from './components/EditItemView';
import { prefixString } from '../common/util/stringUtils';
import SelectField from '../common/components/SelectField';
import SettingsMenu from './components/SettingsMenu';
import { useCatch } from '../reactHelper';
import { notificationsActions } from '../store';

const useStyles = makeStyles((theme) => ({
    details: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        paddingBottom: theme.spacing(3),
    },
}));

const notificators = [{ type: 'web' }, { type: 'email' }, { type: 'sms' }];
const NotificationPage = () => {
    const classes = useStyles();
    const t = useTranslation();
    const dispatch = useDispatch();
    const [item, setItem] = useState();

    const testNotificators = useCatch(async () => {
        await Promise.all(item.notificators.split(/[, ]+/).map(async (notificator) => {
            const response = await fetch(`/api/tracking//notifications/test/${notificator}`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(item),
            });
            if (!response.ok) {
                throw Error(await response.text());
            }
        }));
    });
    const onItemSaved = (result) => {
        dispatch(notificationsActions.update([result]));
    };
    const validate = () => item && item.type && item.notificators;

    return (
        <EditItemView
            endpoint="notifications"
            item={item}
            setItem={setItem}
            validate={validate}
            onItemSaved={onItemSaved}
            menu={<SettingsMenu />}
            breadcrumbs={['settingsTitle', 'sharedNotification']}
        >
            {item && (
                <>
                    <Accordion defaultExpanded>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="subtitle1">
                                {t('sharedRequired')}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.details}>
                            <SelectField
                                value={item.type}
                                emptyValue={null}
                                onChange={(e) => setItem({ ...item, type: e.target.value })}
                                endpoint="/api/tracking/notifications/types"
                                keyGetter={(it) => it.type}
                                titleGetter={(it) => t(prefixString('event', it.type))}
                                label={t('sharedType')}
                            />
                            <SelectField
                                multiple
                                value={item.notificators ? item.notificators.split(/[, ]+/) : []}
                                onChange={(e) => setItem({ ...item, notificators: e.target.value.join() })}
                                data={notificators}
                                keyGetter={(it) => it.type}
                                titleGetter={(it) => t(prefixString('notificator', it.type))}
                                label={t('notificationNotificators')}
                            />
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={testNotificators}
                                disabled={!item.notificators}
                            >
                                {t('sharedTestNotificators')}
                            </Button>
                            <FormGroup>
                                <FormControlLabel
                                    control={(
                                        <Checkbox
                                            checked={item.always}
                                            onChange={(event) => setItem({ ...item, always: event.target.checked })}
                                        />
                                    )}
                                    label={t('notificationAlways')}
                                />
                            </FormGroup>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="subtitle1">
                                {t('sharedExtra')}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.details}>
                            <SelectField
                                value={item.calendarId || 0}
                                onChange={(event) => setItem({ ...item, calendarId: Number(event.target.value) })}
                                endpoint="/api/tracking/calendars"
                                label={t('sharedCalendar')}
                            />
                        </AccordionDetails>
                    </Accordion>
                </>
            )}
        </EditItemView>
    );
};

export default NotificationPage;
