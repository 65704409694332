import { useSelector } from 'react-redux';

export const useManager = () => useSelector((state) => state.session.user.manager);

export const useDeviceReadonly = () => useSelector((state) => {
    const { manager, readOnly, deviceReadonly } = state.session.user;
    return !manager && (readOnly || deviceReadonly);
});
export const useLimitCommands = () => useSelector((state) => {
    const { manager, limitCommands } = state.session.user;
    return !manager && limitCommands;
});

export const useRestriction = (key) => useSelector((state) => {
    const { manager } = state.session.user;
    const userValue = state.session.user[key];
    return !manager && userValue;
});
